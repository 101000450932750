
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Text, H3 } from 'library/atoms/Typography';
import Head from 'next/head';
import styled from '@emotion/styled';
import Layout from 'library/atoms/Layout';
import useTranslation from 'next-translate/useTranslation';
import getUrlParams from 'helpers/getUrlParams';
import { ON_SERVER } from 'shared/enums/environment';
import { useRouter } from 'next/router';
import { OutletPresenter } from 'shared/presenters/OutletPresenter';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';
import { Query_Root } from 'shared/presenters/graphqlTypes';
import Link from 'next/link';
import widgetRoutes, { EOLA_ROUTES } from 'enums/routes';
import { ShowOnMarketplace } from 'shared/library/atoms/ConditionalMarketplace';
import Icon from 'shared/library/atoms/Icon';
const Custom404 = () => {
    const { t } = useTranslation('404');
    const { asPath } = useRouter();
    const widgetSlug = getUrlParams(asPath).widgetSlug;
    useEffect(() => {
        Sentry.captureMessage(`404 from ${asPath}`);
    }, [asPath]);
    const { data: widgetData } = useQuery<Pick<Query_Root, 'widgets'>>(FETCH_OUTLET_DETAILS, {
        variables: {
            widgetSlug
        },
        skip: ON_SERVER,
        ssr: ON_SERVER
    });
    const outletJSON = widgetData?.widgets?.[0]?.outlet;
    const outlet = outletJSON && new OutletPresenter(outletJSON);
    const phoneHref = `tel:${outlet?.phone_number}`;
    const emailHref = `mailto:${outlet?.email}`;
    return (<>
      <Head>
        <title>{t('page-not-found')}</title>
      </Head>
      <Layout>
        <StyledContent>
          <ShowOnMarketplace>
            <Link href={EOLA_ROUTES.MARKETPLACE.url()} passHref>
              <Icon name="logo" size={70} color="grey"/>
            </Link>
          </ShowOnMarketplace>
          <H3 mb={3}>{t('page-not-found')}</H3>
          <Text>{t('instructions')}</Text>
          {outlet && (<>
              <Link href={widgetRoutes.HOME.url({ widgetSlug })} passHref>
                <Text>Go back</Text>
              </Link>
              <Text>{t('further-difficulties')}</Text>
              <Text>{outlet?.name}</Text>
              <Text>
                {outlet?.phone_number && (<span>
                    {t('telephone')}:&nbsp;
                    <a href={phoneHref} title={`${t('telephone')}: ${outlet?.phone_number}`}>
                      {outlet?.phone_number}
                    </a>
                  </span>)}
              </Text>
              <Text>
                {outlet?.email && (<span>
                    {t('email')}:&nbsp;
                    <a href={emailHref} title={`${t('email')}: ${outlet?.email}`}>
                      {outlet?.email}
                    </a>
                  </span>)}
              </Text>
            </>)}
        </StyledContent>
      </Layout>
    </>);
};
export default Custom404;
const FETCH_OUTLET_DETAILS = gql `
  query WidgetCustom404FetchWidget($widgetSlug: String) {
    widgets(where: { slug: { _eq: $widgetSlug } }) {
      outlet {
        id
        name
        phone_number
        email
      }
    }
  }
`;
const StyledContent = styled.main(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    margin: 0,
    '> * + *': {
        marginTop: theme.space[3]
    }
}));

    async function __Next_Translate__getStaticProps__1961a2560cc__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1961a2560cc__ as getStaticProps }
  