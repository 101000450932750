import { NextRouter } from 'next/router'

// This function can be used where we can't access the query using router.query
// We use this in the error.tsx file where widgetSlug cannot be accessed
// router.query should be used where possible but this can act as a fallback

const getUrlParams = (path: NextRouter['asPath']) => {
  const subPath = path.substring(1)
  const array = subPath.split('/')
  const params = {
    widgetSlug: array?.[0],
    categorySlug: array?.[1],
    activitySlug: array?.[2]
  }

  return params
}

export default getUrlParams
